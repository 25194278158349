import React from "react"
import ReactDOM from 'react-dom'
import 'flickity/dist/flickity.min.css'

const Flickity = typeof window !== "undefined" ? require("flickity") : () => null
//eslint-disable-next-line
const ImagesLoaded = typeof window !== 'undefined' ? require('flickity-imagesloaded') : () => null
//eslint-disable-next-line
const Fade = typeof window !== 'undefined' ? require('flickity-fade') : () => null

export default props => {
    if(typeof window === 'undefined'){
        return null
    }else{
        return (
            <SubSlider {...props} />
        )
    }

}

class SubSlider extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            flickityReady: false,
        }

        this.refreshFlickity = this.refreshFlickity.bind(this)
    }

    setupFlickity(){
        if(!this.flickity) {
            this.flickity = new Flickity(this.flickityNode, this.props.options || {})
    
            if(this.props.flickityRef){
                this.props.flickityRef(this.flickity)
            }
    
            this.setState({
                flickityReady: true,
            })
        }
    }

    componentDidMount() {
        this.setupFlickity()

        setTimeout(() => {
            this.flickity.resize()
        },1000)
    }

    componentDidUpdate(prevProps, prevState) {
        this.setupFlickity()
    }

    refreshFlickity() {
        this.flickity.reloadCells()
        this.flickity.resize()
        this.flickity.updateDraggable()
    }

    componentWillUnmount() {
        //this.flickity.destroy()
    }

    componentDidUpdate(prevProps, prevState) {
        const flickityDidBecomeActive = !prevState.flickityReady && this.state.flickityReady
        const childrenDidChange = prevProps.children.length !== this.props.children.length

        if (flickityDidBecomeActive || childrenDidChange) {
            this.refreshFlickity()
        }
    }

    renderPortal() {
        if (!this.flickityNode) {
            return null
        }

        const mountNode = this.flickityNode.querySelector('.flickity-slider')

        if (mountNode) {
            return ReactDOM.createPortal(this.props.children, mountNode)
        }
    }

    render() {
        const {
            className,
            flickityRef
        } = this.props

        return [
            <div
                className={className}
                key="flickityBase"
                ref={node => {
                    this.flickityNode = node
                }}
            />,
            this.renderPortal(),
        ].filter(Boolean)
    }
}